import style from './index.module.scss';
import { Menu } from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { remove } from 'react-cookies';
const Layout = ({ userInfo }) => {
    const navigate = useNavigate();
    function getItem(label, key, icon, children, type) {
        return {
            key,
            icon,
            children,
            label,
            type,
        };
    }
    const items = [
        getItem(
            'Data overview',
            'sub1',
            <img
                src="https://yumi-frontend-assets.s3.ap-east-1.amazonaws.com/yumi/components/1688442398127_kyc-tools-data.svg"
                alt=""
            />,
            [getItem('User data Detail', 'UserDetail')],
        ),
        getItem(
            'Change log',
            'ChangeLog',
            <img
                src="https://yumi-frontend-assets.s3.ap-east-1.amazonaws.com/yumi/components/1688461730070_changelog.svg"
                alt=""
            />,
        ),
        getItem(
            'Configurations',
            'Configurations',
            <img
                src="https://yumi-frontend-assets.s3.ap-east-1.amazonaws.com/yumi/components/1688461783228_settings.svg"
                alt=""
            />,
        ),
        getItem('Log Out', 'Log Out', ''),
    ];
    const rootSubmenuKeys = ['sub1', 'ChangeLog', 'Configurations'];
    const [openKeys, setOpenKeys] = useState(['sub1']);
    const onOpenChange = (keys) => {
        navigate('/');
        const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
        if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
            setOpenKeys(keys);
        } else {
            setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
        }
    };
    return (
        <div className={style['Layout']}>
            <div className="user">
                <div className="user-info">
                    <img
                        src="https://yumi-frontend-assets.s3.ap-east-1.amazonaws.com/yumi/icons/1687660384409_kyc-progress.svg"
                        alt=""
                    />
                    <span>{userInfo?.name}</span>
                </div>
                <div className="cells">
                    <Menu
                        mode="inline"
                        openKeys={openKeys}
                        onOpenChange={onOpenChange}
                        style={{
                            width: 250,
                            backgroundColor: 'unset',
                            color: '#fff',
                        }}
                        onClick={(e) => {
                            if (e.key === 'Log Out') {
                                remove('kyc-admin-user');
                                navigate('/login');
                            } else {
                                navigate(e.key);
                            }
                        }}
                        items={items}
                    />
                </div>
            </div>
        </div>
    );
};
const mapStateToProps = (state) => {
    return { userInfo: state.Store.userInfo };
};
export default connect(mapStateToProps, {})(Layout);
