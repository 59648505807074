import blobToBase64 from '@/utils';
import service from '.';
import _ from 'lodash';
//获取kyc列表
export const queryKycList = (info, page, args) => {
    let { pid, name, email, workflowId, risk_desc, onfidoStatus, level_desc, source, limit } = args;
    return service.get(
        `/admin/kycOverviewList?${info ?? ''}${page ? '&page=' + page : ''}${
            '&limit=' + (limit ?? 100)
        }${pid ? '&principal_id=' + pid : ''}${name ? '&name=' + name : ''}${
            email ? '&email=' + email : ''
        }${workflowId ? '&workflowId=' + workflowId : ''}${
            risk_desc ? '&risk_desc=' + risk_desc : ''
        }${onfidoStatus ? '&onfidoStatus=' + onfidoStatus : ''}${
            level_desc ? '&level_desc=' + level_desc.toLocaleLowerCase() : ''
        }${source ? '&source=' + source : ''}`,
    );
};

// 添加link pid
export const addLinkAccount = (info) => service.post('/admin/linkKycAccount', info);

//删除link pid
export const removeLinkAccount = (pid) =>
    service.post('/admin/removeLinkKycAccount', { principal_id: pid });

//修改用户risk等级
export const changeRisk = (info) => service.post('/admin/updateKycRisk', info);

//修改 review
export const changeReview = (info) => service.post('/admin/updateReviewStatus', info);

//修改用户tier等级
export const changeTier = (info) => service.post('/admin/updateKycLevel', info);

//reject
export const rejectTier = (info) => service.post('/admin/rejectKycReview', info);

//查询kyc记录详情
export const queryDetail = (pid) => service.get(`/admin/getKycOverview?principal_id=${pid}`);

//查看用户上传图片文件流
export const queryDocument = (pid) => service.get(`/admin/getKycDocumentDetailList?pid=${pid}`);

//查看用户图片信息
export const queryDocumentContent = (filename, str) =>
    service.get(`admin/getKycDocumentContent?doc_id=${filename}&customer_id=${str}`);

//获取onfido图片信息
export const queryOnfidoContent = (id) => {
    console.debug('🚀 ~ file: kycList.js:79 ~ id:', id);
    return service.get(`admin/getOnfidoDocumentContent?document_id=${id}`).then((res) => {
        console.debug('🚀 ~ file: kycList.js:41 ~ returnservice.get ~ res:', res);
        return res;
    });
};

// ==========================查询checklist==========================

export const queryCheckList = async (id) => {
    try {
        return await service.get(`v2/admin/getOnfidoCheckList?applicant_id=${id}`).then((res) => {
            if (res.code === 200) {
                return res.data.checks.map((i) => i.report_ids[0]);
            } else {
                console.error('queryCheckList error', res);
            }
        });
    } catch (error) {
        return await queryCheckList(id);
    }
};

// ==========================查询单个list信息==========================

export const queryCheckInfo = async (reportId) => {
    try {
        return await service
            .get(`/v2/admin/getOnfidoReport?report_id=${reportId}`, {
                headers: { mode: 'no-cors' },
            })
            .then((res) => {
                if (res.code === 200) {
                    return res.data;
                } else {
                    console.error('queryCheckInfo error', res);
                }
            });
    } catch (error) {
        return await queryCheckInfo(reportId);
    }
};

// ==========================查询上传文件列表==========================

export const queryOnfidoUploadList = async (id) => {
    try {
        return await service
            .get(`/v2/admin/getOnfidoDocumentList?applicant_id=${id}`, {
                headers: { mode: 'no-cors' },
            })
            .then((res) => {
                if (res.code === 200) {
                    return _.groupBy(
                        res.data.documents.map((i) => ({ type: i.type, id: i.id })),
                        'type',
                    );
                } else {
                    console.error('queryOnfidoUploadList error', res);
                }
            });
    } catch (error) {
        return await queryOnfidoUploadList(id);
    }
};

// ==========================查询文件图片资源==========================

export const queryOnfidoImgSrc = async (id) => {
    try {
        return await service
            .get(`/v2/admin/getOnfidoDocumentContent?document_id=${id}`, {
                headers: { mode: 'no-cors' },
            })
            .then((res) => {
                return blobToBase64(res);
            });
    } catch (error) {
        return await queryOnfidoImgSrc(id);
    }
};

// ==========================查询用户图片列表==========================

export const queryOnfidoUserPhotoList = async (id) => {
    try {
        return await service
            .get(`/v2/admin/getOnfidoPhotoList?applicant_id=${id}`, {
                headers: { mode: 'no-cors' },
            })
            .then((res) => {
                if (res.code === 200) {
                    return res.data.live_photos.map((i) => i.id);
                } else {
                    console.error('getOnfidoPhotoLists error', res);
                }
            });
    } catch (error) {
        return await queryOnfidoUserPhotoList(id);
    }
};
// ==========================查询用户photo资源==========================

export const queryOnfidoUserPhotoSrc = async (id) => {
    if (!id) {
        return;
    }
    try {
        return await service
            .get(`/v2/admin/getOnfidoPhotoDownload?live_photo_id=${id}`, {
                headers: { mode: 'no-cors' },
            })
            .then(async (res) => {
                console.debug('🚀 ~ file: kycList.js:143 ~ .then ~ res:', res);
                return blobToBase64(res);
            });
    } catch (error) {
        return await queryOnfidoUserPhotoSrc(id);
    }
};
