import style from './index.module.scss';
import { addAdmin, changeAdmin } from '@/Api/admin';
import { useCallback, useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { message } from 'antd';
const AddAccount = ({ userList }) => {
    let nameRef = useRef();
    let pwdRef = useRef();
    let emailRef = useRef();
    let roleRef = useRef();
    const [searchparams] = useSearchParams();
    const user = searchparams.get('admin');
    const creatAdmin = useCallback(() => {
        if (user) {
            changeAdmin({
                userName: nameRef.current.value,
                passWord: pwdRef.current.value,
                role: roleRef.current.value,
                email: emailRef.current.value,
            }).then((res) => {
                message.success('Modified successfully！');
                console.log(res, 'res');
            });
        } else {
            addAdmin({
                userName: nameRef.current.value,
                passWord: pwdRef.current.value,
                role: roleRef.current.value,
                email: emailRef.current.value,
            }).then((res) => {
                message.success('Add successfully！');
                console.log(res, 'res');
            });
        }
    }, [user]);

    useEffect(() => {
        if (user) {
            userList.forEach((item) => {
                if (item.name === user) {
                    nameRef.current.value = item.name;
                    emailRef.current.value = item.email || '';
                    roleRef.current.value = item.role;
                }
            });
        }
    }, [user, userList]);

    return (
        <div className={style['add-account']}>
            <div className="cells">
                <div className="cell">
                    <div className="label">Role</div>
                    <div className="value">
                        <select type="text" ref={roleRef}>
                            <option value="readonly">readonly</option>
                            <option value="full">full</option>
                        </select>
                    </div>
                </div>
                <div className="cell">
                    <div className="label">User name</div>
                    <div className="value">
                        <input type="text" ref={nameRef} disabled={user} />
                    </div>
                </div>
                <div className="cell">
                    <div className="label">Email</div>
                    <div className="value">
                        <input type="text" ref={emailRef} />
                    </div>
                </div>
                <div className="cell">
                    <div className="label">Password</div>
                    <div className="value">
                        <input type="text" ref={pwdRef} />
                    </div>
                </div>
            </div>
            <div className="btn" onClick={() => creatAdmin()}>
                Save
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return { userList: state.Store.userList };
};

export default connect(mapStateToProps, {})(AddAccount);
