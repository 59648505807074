import style from './index.module.scss';

import { Table } from 'antd';

const Tables = ({ columns, scroll, data, loading, filterValueCount }) => {
    return (
        <div className={style['tables']} id="tables">
            <Table
                columns={columns}
                scroll={scroll}
                dataSource={(data.docs && data.docs.filter((item) => !item.linkPrincipalId)) || []}
                loading={loading}
                pagination={{
                    showSizeChanger: false,
                    hideOnSinglePage: true,
                }}
            />
        </div>
    );
};

export default Tables;
