import style from './index.module.scss';
import TableStyle from '@/Components/Tables/index.module.scss';
import SelectUser from '@/Components/Select';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Space, Button, Input, Dropdown, Menu, Popover, Popconfirm, Tooltip, message } from 'antd';
import { SearchOutlined, MoreOutlined, CopyOutlined, CloseOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import moment from 'moment-timezone';
import { Table } from 'antd';
import {
    queryKycList,
    changeRisk,
    changeTier,
    removeLinkAccount,
    changeReview,
} from '@/Api/kycList';
import LinkPidModal from './component/LinkPidModal';
import ChangeModal from './component/ChangeModal';
import ApplicantDetailsModal from '@/Components/ApplicantDetailsModal';
import { setMainList } from '@/store/action';
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import RejectMOdal from './component/RejectModal';
import CopyToClipboard from 'react-copy-to-clipboard';
const Overview = ({ dataList }) => {
    const navigate = useNavigate();
    const searchInput = useRef(null);
    const [searchText, setSearchText] = useState(undefined);
    const [searchedColumn, setSearchedColumn] = useState('');
    const [filterValueCount, setFilterValueCount] = useState(undefined);
    console.debug('🚀 ~ Overview ~ filterValueCount:', filterValueCount);
    const [linkModal, setLinkModal] = useState(false);
    const [rejectModal, setRejectModal] = useState(false);
    const [riskModal, setRiskModal] = useState(false);
    const [detailModal, setDetailModal] = useState(false);
    const currentModal = useRef();
    const mainAccount = useRef(null);
    const currentSelect = useRef();
    const dispatch = useDispatch();
    const [tier, setTier] = useState();
    const [risk, setRisk] = useState();
    const [reject, setReject] = useState();

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);
    const [allData, setAllData] = useState();
    useEffect(() => {
        queryKycList(undefined, 0, { limit: 99999 })
            .then((r) => setAllData(r.docs))
            .catch((e) => {
                message.error(e, 4);
            });
    }, []);
    // 筛选条件
    const [pid, setPid] = useState();
    const [name, setName] = useState();
    const [email, setEmail] = useState();
    const [workflowId, setWorkflowId] = useState();
    const [risk_desc, setRiskDesc] = useState();
    const [onfidoStatus, setOnfidoStatus] = useState();
    const [level_desc, setLevelDesc] = useState();
    const [platform, setPlatform] = useState();

    const handleMenuClick = useCallback(
        (e, type) => {
            mainAccount.current = type.principal_id;
            if (e.key === 'check') {
                setDetailModal(true);
            } else if (e.key === 'linked') {
                setLinkModal(true);
            } else {
                setRejectModal(true);
            }
        },
        [mainAccount],
    );
    const filteredData = data[page - 1]?.flat().filter((s) => {
        return !(
            reject === 2 &&
            s.seenFaceFlag &&
            s.seen_accounts &&
            s.seen_accounts.length !== 0 &&
            s.seen_accounts.indexOf(s.principal_id) !== -1
        );
    });
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        // ["principal_id", "name", "email", "workflowId", "risk_desc", "onfidoStatus", "level_desc"];
        if (dataIndex === 'principal_id') {
            setPid(selectedKeys[0]);
        }
        if (dataIndex === 'name') {
            setName(selectedKeys[0]);
        }
        if (dataIndex === 'email') {
            setEmail(selectedKeys[0]);
        }
        if (dataIndex === 'workflowId') {
            setWorkflowId(selectedKeys[0]);
        }
        if (dataIndex === 'risk_desc') {
            setRiskDesc(selectedKeys[0]);
        }
        if (dataIndex === 'onfidoStatus') {
            setOnfidoStatus(selectedKeys[0]);
        }
        if (dataIndex === 'level_desc') {
            setLevelDesc(selectedKeys[0]);
        }
        if (dataIndex === 'source') {
            setPlatform(selectedKeys[0]);
        }
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
        setFilterValueCount(undefined);
    };
    let info = `${tier ? 'kyc_level=' + tier + '&' : ''}${risk ? 'risk=' + risk + '&' : ''}${
        reject ? 'is_rejected=' + reject : ''
    }`;
    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                setTotal(0);
                setPage(1);
                const r = await queryKycList(info, 1, {
                    pid,
                    name,
                    email,
                    workflowId,
                    risk_desc,
                    onfidoStatus,
                    level_desc,
                    source: platform,
                });
                setData([r.docs]);
                setTotal(r.totalDocs);
            } catch (error) {
            } finally {
                setLoading(false);
            }
        })();
    }, [
        tier,
        risk,
        reject,
        info,
        pid,
        name,
        email,
        workflowId,
        risk_desc,
        onfidoStatus,
        level_desc,
        platform,
    ]);

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                const r = await queryKycList(info, page, {
                    pid,
                    name,
                    email,
                    workflowId,
                    risk_desc,
                    onfidoStatus,
                    level_desc,
                    source: platform,
                });
                setTotal(r.totalDocs);
                setData((prev) => {
                    const prev_data = [...prev];
                    prev_data[page - 1] = r.docs;
                    return prev_data;
                });
            } catch (error) {
            } finally {
                setLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, pid, name, email, workflowId, risk_desc, onfidoStatus, level_desc, platform]);

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                setTotal(0);
                setPage(1);
                const r = await queryKycList(info, 1, {
                    pid,
                    name,
                    email,
                    workflowId,
                    risk_desc,
                    onfidoStatus,
                    level_desc,
                    source: platform,
                });
                setData([r.docs]);
                setTotal(r.totalDocs);
            } catch (error) {
            } finally {
                setLoading(false);
            }
        })();
    }, [info, pid, name, email, workflowId, risk_desc, onfidoStatus, level_desc, platform]);
    const queryListFun = useCallback(
        (
            info = `${tier ? 'kyc_level=' + tier + '&' : ''}${risk ? 'risk=' + risk + '&' : ''}${
                reject ? 'is_rejected=' + reject : ''
            }`,
        ) => {
            setLoading(true);
            queryKycList(info, page, {
                pid,
                name,
                email,
                workflowId,
                risk_desc,
                onfidoStatus,
                level_desc,
                source: platform,
            })
                .then((res) => {
                    dispatch(setMainList(res));
                    setTotal(res.totalDocs);
                    setData((prev) => {
                        const prev_data = [...prev];
                        prev_data[page - 1] = res.docs;
                        return prev_data;
                    });
                    setLoading(false);
                })
                .catch((res) => {
                    if (res.response.data.message === 'jwt malformed') {
                        navigate('/login');
                    }
                });
        },
        [
            dispatch,
            email,
            level_desc,
            name,
            navigate,
            onfidoStatus,
            page,
            pid,
            reject,
            risk,
            risk_desc,
            tier,
            workflowId,
            platform,
        ],
    );
    const filterList = useCallback((value, type) => {
        if (type === 'tier') {
            setTier(value);
        } else if (type === 'risk') {
            setRisk(value);
        } else {
            if (value === 'Not rejected') {
                setReject(2);
            } else if (value === 'Rejected') {
                setReject(1);
            } else {
                setReject(null);
            }
        }
    }, []);

    const removeLink = useCallback(
        (e) => {
            setLoading(true);
            removeLinkAccount(e).then(() => queryListFun());
        },
        [queryListFun],
    );

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => {
                        handleSearch(selectedKeys, confirm, dataIndex);
                    }}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => {
                            handleSearch(selectedKeys, confirm, dataIndex);
                        }}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => {
            if (['principal_id', 'name', 'email', 'workflowId', 'source'].includes(dataIndex)) {
                return <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />;
            }
        },
        onFilter: (value, record) => {
            // console.debug("🚀 ~ getColumnSearchProps ~ value:", value)
            // console.debug('🚀 ~ getColumnSearchProps ~ record:', dataIndex);
            // if (dataIndex === 'workflowId') {
            //     return `https://dashboard.onfido.com/results/${record.workflowRunId ?? ''}`
            //         .toString()
            //         .toLowerCase()
            //         .includes(value.toLowerCase());
            // } else {
            //     return (record[dataIndex] ?? '')
            //         .toString()
            //         .toLocaleLowerCase()
            //         .includes(value.toLocaleLowerCase());
            // }
            return true;
        },
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) => {
            if (searchedColumn === dataIndex) {
                return (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text ? text.toString() : ''}
                    />
                );
            } else if (dataIndex === 'workflowRunID') {
                return (
                    <a
                        href={`https://dashboard.onfido.com/results/${text}`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {text}
                    </a>
                );
            } else if (dataIndex === 'updatedTime' || dataIndex === 'updatedTimeOnYumi') {
                return moment
                    .utc(text)
                    .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
                    .format('ddd - YYYY/MM/DD HH:mm:ss z');
            }
            return text;
        },
        // sorter: compares(dataIndex),
    });

    const sortOrder = ['Not Started', 'In progress', 'Done', 'Automatic'];
    const columns = [
        {
            title: 'Platform',
            key: 'platform',
            dataIndex: 'source',
            ...getColumnSearchProps('source'),
            render: (e, _value) => {
                return _value.source ?? 'YUMI';
            },
        },
        {
            title: 'Principal ID',
            key: 'principal_id',
            dataIndex: 'principal_id',
            ...getColumnSearchProps('principal_id'),
            render: (e, _value) => {
                return (
                    <>
                        <div className="tag">{e}</div>
                        {_value.child_accounts.map((item) => {
                            return (
                                <div closable className="tag">
                                    {item}
                                    <Popconfirm
                                        title="Delete the Principal ID"
                                        description="Are you sure to delete this Principal ID?"
                                        onConfirm={() => {
                                            removeLink(item);
                                        }}
                                        okText="Yes"
                                        cancelText="No"
                                    >
                                        <CloseOutlined />
                                    </Popconfirm>
                                </div>
                            );
                        })}
                    </>
                );
            },
        },
        {
            title: 'Review Status',
            key: 'Review Status',
            dataIndex: 'reviewStatus',
            ...getColumnSearchProps('reviewStatus'),
            sorter: (a, b) => {
                const aIndex = sortOrder.indexOf(a.reviewStatus);
                const bIndex = sortOrder.indexOf(b.reviewStatus);
                return aIndex - bIndex;
            },
            render: (e, value) => {
                return (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <div className={`reject ${e}`}>{e}</div>
                        {e && (
                            <span
                                className="tier-icon"
                                onClick={() => {
                                    setRiskModal(true);
                                    currentModal.current = 'review';
                                    currentSelect.current = value;
                                }}
                            ></span>
                        )}
                    </div>
                );
            },
        },
        {
            title: 'Name',
            key: 'name',
            dataIndex: 'name',
            ...getColumnSearchProps('name'),
        },
        {
            title: 'Email',
            key: 'email',
            dataIndex: 'email',
            ...getColumnSearchProps('email'),
            render: (e, value) => {
                const show =
                    value.seenFaceFlag && value.seen_accounts && value.seen_accounts.length !== 0;
                return show ? (
                    <Tooltip
                        placement="top"
                        overlayInnerStyle={{ width: '550px' }}
                        title={
                            <div style={{ whiteSpace: 'nowrap' }}>
                                {value.seen_accounts.map((i) => (
                                    <div>{i}</div>
                                ))}
                            </div>
                        }
                    >
                        <div style={{ backgroundColor: '#7953ff', cursor: 'pointer' }}>{e}</div>
                    </Tooltip>
                ) : (
                    <div>{e}</div>
                );
            },
        },
        {
            title: 'Workflow id',
            key: 'workflowId',
            dataIndex: 'workflowId',
            ...getColumnSearchProps('workflowId'),
            render: (e, value) => {
                return (
                    <>
                        <a
                            href={`https://dashboard.onfido.com/results/${value.workflowRunId}`}
                            target="blank"
                        >
                            https://dashboard.onfido.com/results/{value.workflowRunId}
                        </a>
                        <CopyToClipboard
                            text={`https://dashboard.onfido.com/results/${value.workflowRunId}`}
                        >
                            <CopyOutlined />
                        </CopyToClipboard>
                    </>
                );
            },
        },

        {
            title: 'Risk assessment',
            key: 'risk_desc',
            dataIndex: 'risk_desc',
            ...getColumnSearchProps('risk_desc'),
            sorter: (a, b) => a.risk_desc.slice(-1)[0].localeCompare(b.risk_desc.slice(-1)[0]),
            render: (e, value) => {
                return (
                    <>
                        <span className={`risk ${e}`}>{e}</span>
                        <span
                            className="tier-icon"
                            onClick={() => {
                                setRiskModal(true);
                                currentModal.current = 'risk';
                                currentSelect.current = value;
                            }}
                        ></span>
                    </>
                );
            },
        },
        {
            title: 'Status on onfido',
            key: 'onfidoStatus',
            dataIndex: 'onfidoStatus',
            ...getColumnSearchProps('onfidoStatus'),
        },
        {
            title: 'Last update time on Onfido',
            key: 'onfidoUploadTime',
            dataIndex: 'onfidoUploadTime',
            ...getColumnSearchProps('onfidoUploadTime'),
            sorter: (a, b) => {
                if (!a.onfidoUploadTime) {
                    return -1;
                }
                if (!b.onfidoUploadTime) {
                    return 1;
                }
                return new Date(a.onfidoUploadTime) - new Date(b.onfidoUploadTime);
            },
        },
        {
            title: 'Tier on Yumi',
            key: 'level_desc',
            dataIndex: 'level_desc',
            ...getColumnSearchProps('level_desc'),
            sorter: (a, b) => {
                return a.level_desc.slice(-1) - b.level_desc.slice(-1);
            },
            render: (e, value) => {
                return (
                    <>
                        {e}{' '}
                        <span
                            className="tier-icon"
                            onClick={() => {
                                setRiskModal(true);
                                currentModal.current = 'tier';
                                currentSelect.current = value;
                            }}
                        ></span>
                    </>
                );
            },
        },
        {
            title: 'Level Quota',
            key: 'customizeLevelQuota',
            dataIndex: 'customizeLevelQuota',
            ...getColumnSearchProps('customizeLevelQuota'),
            sorter: (a, b) => {
                if (!a.customizeLevelQuota) {
                    return -1;
                }
                if (!b.customizeLevelQuota) {
                    return 1;
                }
                return Number(a.customizeLevelQuota) - Number(b.customizeLevelQuota);
            },
        },
        {
            title: 'Transaction Volume',
            key: 'transcationVolume',
            dataIndex: 'transcationVolume',
            ...getColumnSearchProps('transcationVolume'),
            sorter: (a, b) => {
                if (!a.transcationVolume) {
                    return -1;
                }
                if (!b.transcationVolume) {
                    return 1;
                }
                return Number(a.transcationVolume) - Number(b.transcationVolume);
            },
        },
        {
            title: 'Upgrade Tier statuses',
            key: 'upgradeStatus',
            dataIndex: 'upgradeStatus',
            ...getColumnSearchProps('upgradeStatus'),
        },
        {
            title: 'Upload Time',
            key: 'documentUploadTime',
            dataIndex: 'documentUploadTime',
            ...getColumnSearchProps('documentUploadTime'),
            sorter: (a, b) => {
                if (!a.documentUploadTime) {
                    return -1;
                }
                if (!b.documentUploadTime) {
                    return 1;
                }
                return new Date(a.documentUploadTime) - new Date(b.documentUploadTime);
            },
        },
        {
            title: 'ACTION',
            key: 'ACTION',
            dataIndex: 'ACTION',
            render: (args, _e) => {
                const btnItem = [
                    {
                        label: 'Submit and continue',
                        key: '1',
                    },
                ];
                return (
                    <div className="option-btn">
                        <Dropdown
                            menu={{
                                btnItem,
                            }}
                            trigger={['click']}
                            overlay={
                                <Menu onClick={(e) => handleMenuClick(e, _e)}>
                                    <Menu.Item key={'check'}>Check the details</Menu.Item>
                                    <Menu.Item key={'linked'}>Linked account</Menu.Item>
                                    <Menu.Item key={'rejected'}>Rejected</Menu.Item>
                                </Menu>
                            }
                        >
                            <div>
                                <MoreOutlined
                                    style={{
                                        fontSize: 24,
                                    }}
                                />
                            </div>
                        </Dropdown>
                        {_e.rejectReason && (
                            <Popover content={_e.rejectMemo} title={false} trigger="hover">
                                <div className="reject">
                                    {_e.rejectReason === 'Restricted Country'
                                        ? 'RC'
                                        : _e.rejectReason}
                                </div>
                            </Popover>
                        )}
                    </div>
                );
            },
        },
    ];

    //修改用户risk 或者 tier
    const changeUser = useCallback(
        (value, limit) => {
            if (value) {
                if (currentModal.current === 'risk') {
                    changeRisk({
                        principal_id: currentSelect.current.principal_id,
                        risk: value,
                    })
                        .then((res) => {
                            queryListFun();
                        })
                        .catch((err) => {
                            console.log(err, 'err');
                        });
                } else if (currentModal.current === 'review') {
                    changeReview({
                        principal_id: currentSelect.current.principal_id,
                        review_status: value,
                    })
                        .then((res) => {
                            queryListFun();
                        })
                        .catch((err) => {
                            console.log(err, 'err');
                        });
                } else {
                    setLoading(true);
                    console.log(limit, 'limit');
                    changeTier(
                        limit
                            ? {
                                  principal_id: currentSelect.current.principal_id,
                                  key_level: value,
                                  level_amount: limit,
                              }
                            : {
                                  principal_id: currentSelect.current.principal_id,
                                  key_level: value,
                              },
                    ).then((res) => {
                        queryListFun();
                    });
                }
            }
        },
        [queryListFun],
    );

    return (
        <div className={style['overview']}>
            <SelectUser
                columns={columns}
                reject={true}
                filterList={filterList}
                csvData={allData?.map((item) => {
                    return {
                        principal_id: item.principal_id,
                        name: item.name,
                        email: item.email[0],
                        risk_desc: item.risk_desc,
                        onfidoStatus: item.onfidoStatus,
                        onfidoUploadTime: item.onfidoUploadTime,
                        level_desc: item.level_desc,
                        customizeLevelQuota: item.customizeLevelQuota,
                        transcationVolume: item.transcationVolume,
                        upgradeStatus: item.upgradeStatus,
                        updateTime: item.documentUploadTime,
                    };
                })}
                csvHeader={columns.map((item) => item.key).slice(0, -1)}
            />

            <div className={TableStyle['tables']} id="tables">
                <Table
                    columns={columns}
                    dataSource={filteredData}
                    scroll={{ x: 100 }}
                    loading={loading}
                    key={info}
                    pagination={{
                        showSizeChanger: false,
                        hideOnSinglePage: true,
                        pageSize: 100,
                        total,
                        onChange: async (page) => {
                            setPage(page);
                        },
                    }}
                />
            </div>
            <LinkPidModal
                open={linkModal}
                cancel={() => setLinkModal(!linkModal)}
                account={mainAccount.current}
                update={() => queryListFun()}
            />
            <ChangeModal
                open={riskModal}
                cancel={() => setRiskModal(!riskModal)}
                current={currentModal.current}
                changeUser={changeUser}
            />
            <RejectMOdal
                open={rejectModal}
                cancel={() => setRejectModal(!rejectModal)}
                update={() => queryListFun()}
                account={mainAccount.current}
            />
            {detailModal ? (
                <ApplicantDetailsModal
                    visible={detailModal}
                    account={mainAccount.current}
                    onClose={() => {
                        setDetailModal(false);
                    }}
                    update={() => queryListFun()}
                />
            ) : (
                ''
            )}
        </div>
    );
};

const mapStateToProps = (state) => {
    return { dataList: state.Store.dataList };
};

export default connect(mapStateToProps, { setMainList })(Overview);
