import service from '.';

//获取管理员列表
export const apiGetAdminList = () => service.get('/admin/adminList');

//添加管理员
export const addAdmin = (info) => service.post('/admin/createAdmin', info);

//修改管理员
export const changeAdmin = (info) => service.post('/admin/editAdmin', info);
