import style from './index.module.scss';
import { Button, message, Modal } from 'antd';
import { useCallback, useRef, useState } from 'react';
import { addLinkAccount } from '@/Api/kycList';
import { queryKycList } from '@/Api/kycList';
import { isPrincipalText } from '@/utils';
const LinkPidModal = ({ open, account, cancel, update, info }) => {
    let [loading, setLoading] = useState(false);
    let pidRef = useRef(null);
    //绑定pid
    const linkFunction = useCallback(() => {
        if (!isPrincipalText(pidRef.current.value)) {
            message.error(`please input valid pid`, 3);
            return;
        }

        setLoading(true);
        // 先查该pid的等级
        queryKycList({}, 1, { pid: pidRef.current.value })
            .then((r) => {
                if (r?.docs[0].level_desc === 'Tier0') {
                    throw new Error("main account can't be Tier0");
                }
            })
            .then(() => {
                addLinkAccount({
                    main_principal_id: pidRef.current.value,
                    principal_id: account,
                })
                    .then((res) => {
                        message.success('Add successfully');
                        setLoading(false);
                        update();
                        cancel();
                    })
                    .catch((err) => {
                        setLoading(false);
                        message.error(`Link error ${err}`, 3);
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            })
            .catch((e) => {
                message.error(e.message, 3);
                setLoading(false);
                return;
            });
    }, [account, cancel, update]);
    return (
        <Modal
            title="Please enter the principal ID"
            open={open}
            destroyOnClose={true}
            footer={null}
            wrapClassName={style['link-pid-modal']}
            onCancel={() => {
                cancel();
            }}
        >
            <input type="text" ref={pidRef} />
            <Button
                style={{
                    backgroundColor: '#8686a5',
                    fontSize: '25px',
                    minWidth: 184,
                    height: 52,
                    borderRadius: '2px',
                    color: '#fff',
                    margin: '94px 0 27px',
                    cursor: 'pointer',
                }}
                loading={loading}
                onClick={linkFunction}
            >
                linked account
            </Button>
        </Modal>
    );
};
export default LinkPidModal;
