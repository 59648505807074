import { combineReducers, createStore } from 'redux';
import cookie from 'react-cookies';
const initialState = {
    userInfo: null || cookie.load('kyc-admin-user'),
    dataList: [],
    userList: [],
};

const Store = (state = initialState, action) => {
    switch (action.type) {
        case 'setList':
            return { ...state, dataList: action.payload };
        case 'setUser':
            return { ...state, userInfo: action.payload };
        case 'setUserList':
            return { ...state, userList: action.payload };
        default:
            return state;
    }
};

const reducers = combineReducers({ Store });

export default createStore(reducers);
