import style from './index.module.scss';
import SelectUser from '@/Components/Select';
import Tables from '@/Components/Tables';
import { useRef, useState, useCallback, useEffect } from 'react';
import { Space, Button, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import moment from 'moment-timezone';
import { setMainList } from '@/store/action';
import { queryKycList, changeRisk } from '@/Api/kycList';
import { connect } from 'react-redux';
import ChangeModal from '../Overview/component/ChangeModal';
const UserDetail = ({ dataList }) => {
    const searchInput = useRef(null);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [list, setList] = useState([]);
    const tier = useRef();
    const risk = useRef();
    const [riskModal, setRiskModal] = useState(false);
    const currentModal = useRef();
    const currentSelect = useRef();
    const [loading, setLoading] = useState(true);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
        ),
        onFilter: (value, record) => {
            return (record[dataIndex] ?? '').toString().toLowerCase().includes(value.toLowerCase());
        },
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) => {
            if (searchedColumn === dataIndex) {
                return (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text ? text.toString() : ''}
                    />
                );
            } else if (dataIndex === 'workflowRunID') {
                return (
                    <a
                        href={`https://dashboard.onfido.com/results/${text}`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {text}
                    </a>
                );
            } else if (dataIndex === 'updatedTime' || dataIndex === 'updatedTimeOnYumi') {
                return moment
                    .utc(text)
                    .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
                    .format('ddd - YYYY/MM/DD HH:mm:ss z');
            }
            return text;
        },
        // sorter: compares(dataIndex),
    });
    const columns = [
        {
            title: 'User Data',
            align: 'left',
            children: [
                {
                    title: 'Principal ID',
                    key: 'principal_id',
                    dataIndex: 'principal_id',
                    ...getColumnSearchProps('principal_id'),
                },
                {
                    title: 'Name',
                    key: 'name',
                    dataIndex: 'name',
                    ...getColumnSearchProps('name'),
                },
                {
                    title: 'Email',
                    key: 'email',
                    dataIndex: 'email',
                    ...getColumnSearchProps('email'),
                },
                {
                    title: 'Risk assessment',
                    key: 'risk_desc',
                    dataIndex: 'risk_desc',
                    ...getColumnSearchProps('risk_desc'),
                    sorter: (a, b) =>
                        a.risk_desc.slice(-1)[0].localeCompare(b.risk_desc.slice(-1)[0]),
                    render: (e, value) => {
                        return (
                            <>
                                <span className={`risk ${e}`}>{e}</span>
                                <span
                                    className="tier-icon"
                                    onClick={() => {
                                        setRiskModal(true);
                                        currentModal.current = 'risk';
                                        currentSelect.current = value;
                                    }}
                                ></span>
                            </>
                        );
                    },
                },
                {
                    title: 'Tier on Yumi',
                    key: 'level_desc',
                    dataIndex: 'level_desc',
                    ...getColumnSearchProps('level_desc'),
                    sorter: (a, b) => {
                        return a.level_desc.slice(-1) - b.level_desc.slice(-1);
                    },
                    render: (e, value) => {
                        return (
                            <>
                                {e}{' '}
                                <span
                                    className="tier-icon"
                                    onClick={() => {
                                        setRiskModal(true);
                                        currentModal.current = 'tier';
                                        currentSelect.current = value;
                                    }}
                                ></span>
                            </>
                        );
                    },
                },
                {
                    title: 'Transaction Volume',
                    key: 'transcationVolume',
                    dataIndex: 'transcationVolume',
                    ...getColumnSearchProps('transcationVolume'),
                    sorter: (a, b) => a.address.length - b.address.length,
                },
                {
                    title: 'Upload Time',
                    key: 'updateTime',
                    dataIndex: 'updateTime',
                    ...getColumnSearchProps('updateTime'),
                    sorter: (a, b) => a.address.length - b.address.length,
                },
            ],
        },
        {
            title: 'Onfido Data',
            align: 'left',
            children: [
                {
                    title: 'Workflow Run ID',
                    key: 'workflowRunId',
                    dataIndex: 'workflowRunId',
                    ...getColumnSearchProps('workflowRunId'),
                },
                {
                    title: 'Status on Onfido',
                    key: 'onfidoStatus',
                    dataIndex: 'onfidoStatus',
                    ...getColumnSearchProps('onfidoStatus'),
                },
                {
                    title: 'Upload Time',
                    key: 'onfidoUploadTime',
                    dataIndex: 'onfidoUploadTime',
                    ...getColumnSearchProps('onfidoUploadTime'),
                    sorter: (a, b) => a.address.length - b.address.length,
                },
                {
                    title: 'Name',
                    key: 'name',
                    dataIndex: 'name',
                    ...getColumnSearchProps('name'),
                },
                {
                    title: 'Email',
                    key: 'email',
                    dataIndex: 'email',
                    ...getColumnSearchProps('email'),
                },
            ],
        },
    ];

    const queryListFun = useCallback(
        (
            info = `${tier.current ? 'kyc_level=' + tier.current + '&' : ''}${
                risk.current ? 'risk=' + risk.current : ''
            }`,
        ) => {
            setLoading(true);
            queryKycList(info, 0, {}).then((res) => {
                console.log(res, 'res');
                setList(res);
                setLoading(false);
            });
        },
        [],
    );
    useEffect(() => {
        queryListFun();
    }, [queryListFun]);
    const filterList = useCallback(
        (value, type) => {
            if (type === 'tier') tier.current = value;
            else {
                risk.current = value;
            }
            queryListFun();
        },
        [queryListFun],
    );
    //修改用户risk 或者 tier
    const changeUser = useCallback(
        (value) => {
            if (value) {
                if (currentModal.current === 'risk') {
                    changeRisk({
                        principal_id: currentSelect.current.principal_id,
                        risk: value,
                    })
                        .then((res) => {
                            queryListFun();
                        })
                        .catch((err) => {
                            console.log(err, 'err');
                        });
                } else {
                }
            }
        },
        [queryListFun],
    );
    return (
        <div className={style['user-table']}>
            <SelectUser
                columns={columns}
                datas={list}
                filterList={filterList}
                csvData={list.docs || []}
                csvHeader={columns
                    .map((item) => {
                        return item.children.map((cld) => cld.dataIndex);
                    })
                    .flat()}
            />
            <Tables columns={columns} scroll={{ x: 2000 }} data={list} loading={loading} />
            <ChangeModal
                open={riskModal}
                cancel={() => setRiskModal(!riskModal)}
                current={currentModal.current}
                changeUser={changeUser}
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    return { dataList: state.Store.dataList };
};

export default connect(mapStateToProps, { setMainList })(UserDetail);
