import { Button, Form, Input } from 'antd';
import { setToken, setUser } from '../../store/action';
import { connect, useDispatch } from 'react-redux';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import style from './index.module.scss';
import { apiLogin } from '@/Api/login';
import { message } from 'antd';
import cookie from 'react-cookies';
const Login = (props) => {
    const navigate = useNavigate();
    const [isInvalidCredentials, setIsInvalidCredentials] = useState(false);

    const dispatch = useDispatch();
    const onFinish = async (values) => {
        // if (values.password === "yumi123" && values.username === "admin") {
        apiLogin({ userName: values.username, passWord: values.password })
            .then((res) => {
                dispatch(setUser(res));
                let inFifteenMinutes = new Date(new Date().getTime() + 24 * 3600 * 1000);
                cookie.save('kyc-admin-user', res, { expires: inFifteenMinutes });
                navigate('/');
            })
            .catch((err) => {
                console.log(err);
                message.error(err.response.data);
            });
        return;
        // fetch(`${REACT_APP_BASE_API_URL}kyc/login`, {
        //   method: "POST",
        //   headers: {
        //     "Content-Type": `application/json; charset=utf-8`,
        //   },
        //   body: JSON.stringify({ userName: "admin", passWords: "yumi123" }),
        // })
        //   .then((res) => res.json())
        //   .then((response) => {
        //     dispatch(setToken(response.token));
        //   });
        // navigate("/panel");
        // } else {
        //   setIsInvalidCredentials(true);
        // }
    };

    return (
        <div className={style['user-login']}>
            <div className="logo"></div>
            <Form
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                style={{ maxWidth: 600 }}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                autoComplete="off"
            >
                <Form.Item
                    label="Username"
                    name="username"
                    validateTrigger="onChange"
                    rules={[{ required: true, message: 'Please input your username!' }]}
                >
                    <Input onChange={() => setIsInvalidCredentials(false)} />
                </Form.Item>

                <Form.Item
                    label="Password"
                    name="password"
                    rules={[{ required: true, message: 'Please input your password!' }]}
                >
                    <Input.Password onChange={() => setIsInvalidCredentials(false)} />
                </Form.Item>
                {isInvalidCredentials && (
                    <p style={{ color: 'red', textAlign: 'center' }}>
                        Please enter valid credentials.
                    </p>
                )}
                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default connect(null, { setToken, setUser })(Login);
