import style from './index.module.scss';
import Tables from '@/Components/Tables';
import { useEffect, useRef, useState } from 'react';
import { Space, Button, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import SelectUser from '@/Components/Select';
import { queryChangeList } from '@/Api/changeLog';
const ChangeLog = () => {
    const [logList, setLogList] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        queryChangeList().then((res) => {
            setLogList(res);
            setLoading(false);
        });
    }, []);

    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
    };
    const handleReset = (clearFilters) => {
        clearFilters();
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
        ),
        onFilter: (value, record) => {
            return (record[dataIndex] ?? '').toString().toLowerCase().includes(value.toLowerCase());
        },
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },

        // sorter: compares(dataIndex),
    });
    const columns = [
        {
            // title: "RowHead",
            dataIndex: 'index',
            key: 'index',
            render: (e, value, index) => {
                return index + 1;
            },
        },
        {
            title: 'Principal ID',
            key: 'principal_id',
            dataIndex: 'principal_id',
            ...getColumnSearchProps('principal_id'),
        },
        {
            title: 'Changed field',
            key: 'changed_field',
            dataIndex: 'changed_field',
            ...getColumnSearchProps('changed_field'),
        },
        {
            title: 'Previous Value',
            key: 'previous_value_desc',
            dataIndex: 'previous_value_desc',
            ...getColumnSearchProps('previous_value_desc'),
            sorter: (a, b) =>
                a.previous_value_desc
                    .slice(-1)[0]
                    .localeCompare(b.previous_value_desc.slice(-1)[0]),
            render: (e, value) => {
                return (
                    <>
                        {value.changed_field === 'Risk' ? (
                            <span className={`risk ${e}`}>{e}</span>
                        ) : (
                            <div>{e}</div>
                        )}
                    </>
                );
            },
        },
        {
            title: 'New Value',
            key: 'new_value_desc',
            dataIndex: 'new_value_desc',
            ...getColumnSearchProps('new_value_desc'),
            sorter: (a, b) =>
                a.new_value_desc.slice(-1)[0].localeCompare(b.new_value_desc.slice(-1)[0]),
            render: (e, value) => {
                return (
                    <>
                        {value.changed_field === 'Risk' ? (
                            <span className={`risk ${e}`}>{e}</span>
                        ) : (
                            <div>{e}</div>
                        )}
                    </>
                );
            },
        },
        {
            title: 'Change Time',
            key: 'createAt',
            dataIndex: 'createAt',
            ...getColumnSearchProps('createAt'),
            sorter: (a, b) => {
                return new Date(a.createAt).getTime() - new Date(b.createAt).getTime();
            },
        },
        {
            title: 'Operator',
            key: 'opt_admin',
            dataIndex: 'opt_admin',
            ...getColumnSearchProps('opt_admin'),
        },
    ];
    return (
        <div className={style['change-log']}>
            <SelectUser
                csvData={logList.docs || []}
                csvHeader={columns.map((item) => item.key).slice(1, -1)}
                selectFlag={true}
            />
            <div className="log-table">
                <Tables columns={columns} data={logList} loading={loading} />
            </div>
        </div>
    );
};
export default ChangeLog;
