import style from './index.module.scss';
import { Button, message, Modal, Select } from 'antd';
import { useState, useCallback } from 'react';
import { rejectTier } from '@/Api/kycList';
const LinkPidModal = ({ open, account, cancel, update }) => {
    let [loading, setLoading] = useState(false);

    const [rejectValue, setRejectValue] = useState(null);
    const [rejectMemo, setRejectMemo] = useState('');
    const rejectFunction = useCallback(() => {
        if (rejectValue === 'Other' && rejectMemo === '') return;
        setLoading(true);
        rejectTier({
            principal_id: account,
            reject_reason: rejectValue,
            reject_memo: rejectMemo,
        })
            .then((res) => {
                message.success(res.msg);
                setLoading(false);
                resultFunction();
            })
            .catch((err) => {
                message.error(err.msg);
                setLoading(false);
                resultFunction();
            });
        // eslint-disable-next-line no-use-before-define, react-hooks/exhaustive-deps
    }, [account, rejectMemo, rejectValue]);
    const onSecondCityChange = useCallback((e) => {
        setRejectValue(e);
    }, []);

    const resultFunction = useCallback(() => {
        setRejectValue(null);
        setLoading(false);
        cancel();
        update();
    }, [cancel, update]);
    return (
        <Modal
            title="Choose the reason for reject"
            open={open}
            destroyOnClose={true}
            footer={null}
            wrapClassName={style['reject-modal']}
            onCancel={() => {
                setRejectValue(null);
                setLoading(false);
                cancel();
            }}
        >
            <Select
                style={{
                    width: 272,
                }}
                defaultValue={'Select reason'}
                onChange={onSecondCityChange}
                options={[
                    { label: 'PEP', value: 'PEP' },
                    { label: 'Restricted Country', value: 'Restricted Country' },
                    { label: 'Invalid Face', value: 'Invalid Face' },
                    { label: 'Invalid ID', value: 'Invalid ID' },
                    { label: 'Expired ID', value: 'Expired ID' },
                    { label: 'Other', value: 'Other' },
                ]}
            />{' '}
            <br />
            <div className="other">
                {rejectValue === 'Other' && <div>*</div>}
                <input
                    type="text"
                    placeholder={rejectValue !== 'Other' && 'This field is not required'}
                    onInput={(e) => setRejectMemo(e.target.value)}
                />
            </div>
            <div className="btn-content">
                <div
                    className="btn cancel"
                    onClick={() => {
                        setRejectValue(null);
                        setLoading(false);
                        cancel();
                    }}
                >
                    Cancel
                </div>
                <Button
                    type="primary"
                    shape="round"
                    className="btn"
                    loading={loading}
                    disabled={!rejectValue || (rejectValue === 'Other' && rejectMemo === '')}
                    onClick={rejectFunction}
                >
                    confirm
                </Button>
            </div>
        </Modal>
    );
};
export default LinkPidModal;
