//在index.js中引入axios
import axios from 'axios';
//引入qs模块，用来序列化post类型的数据
import { message } from 'antd';
import { REACT_APP_BASE_API_URL } from '@/constants';
import cookie from 'react-cookies';
//设置axios基础路径
const service = axios.create({
    baseURL: REACT_APP_BASE_API_URL,
});
// 请求拦截器
service.interceptors.request.use(
    (config) => {
        // 每次发送请求之前本地存储中是否存在token，也可以通过Redux这里只演示通过本地拿到token
        // 如果存在，则统一在http请求的header都加上token，这样后台根据token判断你的登录情况
        // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
        //在每次的请求中添加token
        // config.data = Object.assign({}, config.data, {
        //   token: token,
        // })
        //设置请求头
        // config.headers = {
        //   "Content-type": "application/json",
        // }
        if (cookie.load('kyc-admin-user')) {
            config.headers = {
                Authorization: `Bearer ${cookie.load('kyc-admin-user').jwtToken}`,
            };
        }
        if (
            config.url.indexOf('admin/getKycDocumentContent') !== -1 ||
            config.url.indexOf('admin/getOnfidoDocumentContent') !== -1 ||
            config.url.indexOf('admin/getOnfidoPhotoDownload') !== -1
        ) {
            config = {
                responseType: 'blob',
                ...config,
                headers: { ...config.headers },
            };
        }
        console.debug('🚀 ~ config:', config);
        return config;
    },

    (error) => {
        return error;
    },
);

service.interceptors.response.use(
    (response) => {
        // 根据返回不同的状态码做不同的事情
        // 这里一定要和后台开发人员协商好统一的错误状态码
        if (response.status) {
            switch (response.status) {
                case 200:
                    return response.data;
                case 401:
                    //未登录处理方法
                    break;
                case 403:
                    //token过期处理方法
                    break;
                case 400:
                    message.error(response);
                    break;
                default:
                    message.error(response.data);
            }
        } else {
            return response;
        }
    },
    function (error) {
        throw new Error(error.response.data.msg);
    },
);
export default service;
