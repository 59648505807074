export let REACT_APP_BASE_API_URL = 'https://staging.api2.yumi.io/';

switch (process.env.REACT_APP_ENVIRONMENT) {
    case 'production':
        REACT_APP_BASE_API_URL = 'https://api2.yumi.io/';
        break;
    case 'staging':
        REACT_APP_BASE_API_URL = 'https://staging.api2.yumi.io/';
        break;
    default:
        REACT_APP_BASE_API_URL = 'https://staging.api2.yumi.io/';
}
console.log(process.env.REACT_APP_ENVIRONMENT, REACT_APP_BASE_API_URL);
