import './App.css';
import Layout from '@/views/Layout';
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import cookie from 'react-cookies';
import React from 'react';
// import { Page, Text, View, Document, BlobProvider } from "@react-pdf/renderer";
// import JSZip from "jszip";
// import { saveAs } from "file-saver";
// import _ from "lodash";
// function MyDocument() {
//   return (
//     <Document>
//       <Page>
//         <View>
//           <Text>Hello, world!</Text>
//           <div>sssss</div>
//         </View>
//       </Page>
//     </Document>
//   );
// }

// const MyComponent = memo<{ setBlobs: (prev) => void; id: number }>(({ setBlobs, id }) => {
//   return (
//     <BlobProvider document={<MyDocument />}>
//       {({ blob }) => {
//         blob && setBlobs((prev) => _.uniqBy([...prev, { blob, id }], "id"));
//         return (
//           <a href={blob ? URL.createObjectURL(blob) : ""} download="my-document.pdf">
//             Download PDF
//           </a>
//         );
//       }}
//     </BlobProvider>
//   );
// }, _.isEqual);
function App({ userInfo }) {
    const navigate = useNavigate();

    // const [blobs, setBlobs] = useState<{ blob: Blob; id: number }[]>([]);
    useEffect(() => {
        let queryLastUser = cookie.load('kyc-admin-user');
        if (!userInfo && !queryLastUser) {
            navigate('/login');
        }
    });

    fetch('https://ic-api.internetcomputer.org/api/v3/metrics/cycle-burn-rate').then((r) =>
        r.json(),
    );

    // function downloadZip() {
    //   const zip = new JSZip();

    //   // Generate and add each PDF file to the zip
    //   blobs.forEach((blob, index) => {
    //     zip.file(`my-document-${index}.pdf`, blob.blob);
    //   });
    //   console.debug("🚀 ~ file: App.tsx:56 ~ blobs.forEach ~ blobs:", blobs);

    //   // Generate and download the zip file
    //   zip.generateAsync({ type: "blob" }).then((content) => {
    //     saveAs(content, "documents.zip");
    //   });
    // }
    return (
        <div className="app">
            <Layout />
            <Outlet />
            {/* <div style={{ width: "100px", height: "100px" }} onClick={downloadZip}>
        444
      </div> */}
            {/* <MyComponent setBlobs={setBlobs} id={1} key="pdf-down-1"></MyComponent>
      <MyComponent setBlobs={setBlobs} id={2} key="pdf-down-2"></MyComponent> */}
        </div>
    );
}
const mapStateToProps = (state) => {
    return { userInfo: state.Store.userInfo };
};
export default connect(mapStateToProps, {})(App);
