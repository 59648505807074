import style from './index.module.scss';
import { Modal, Space, Select, InputNumber, message } from 'antd';
import { useCallback, useMemo, useRef, useState } from 'react';
const ChagneModal = ({ open, cancel, current, changeUser }) => {
    const [selectValue, setSelectValue] = useState();
    const [limitFlag, setLimitFlag] = useState(false);
    const limit = useRef();
    const handleChange = useCallback((e) => {
        if (e === 'Tier3') {
            setLimitFlag(true);
        } else {
            setLimitFlag(false);
        }
        setSelectValue(e);
    }, []);
    const options = useMemo(() => {
        if (current === 'risk') {
            return [
                {
                    value: 'Low',
                    label: 'Risk A',
                },
                {
                    value: 'Medium',
                    label: 'Risk B',
                },
                {
                    value: 'High',
                    label: 'Risk C',
                },
            ];
        } else if (current === 'review') {
            return [
                {
                    value: 'Not Started',
                    label: 'Not Started',
                },
                {
                    value: 'In progress',
                    label: 'In progress',
                },
                {
                    value: 'Done',
                    label: 'Done',
                },
                {
                    value: 'Rejected',
                    label: 'Rejected',
                },
            ];
        } else {
            return [
                {
                    value: 'NA',
                    label: 'Tier 0',
                },
                {
                    value: 'Tier1',
                    label: 'Tier 1',
                },
                {
                    value: 'Tier2',
                    label: 'Tier 2',
                },
                {
                    value: 'Tier3',
                    label: 'Tier 3',
                },
            ];
        }
    }, [current]);
    const title = useMemo(() => {
        if (current === 'tier') {
            return 'Change Tier';
        } else if (current === 'review') {
            return 'Change review status';
        } else return 'Change Risk assessment';
    }, [current]);
    return (
        <Modal
            className={style['change-modal']}
            open={open}
            title={title}
            okText="Confim"
            onCancel={() => {
                cancel();
                setLimitFlag(false);
            }}
            onOk={() => {
                if (limitFlag && !limit.current) {
                    message.error('Please fill in the quota！');
                    return;
                }
                changeUser(selectValue, limit.current?.value || undefined);
                cancel();
                setLimitFlag(false);
            }}
            destroyOnClose={true}
        >
            <Space wrap>
                <Select
                    defaultValue="Select a status"
                    style={{
                        width: 120,
                        minWidth: 148,
                    }}
                    onChange={handleChange}
                    options={options}
                />
            </Space>
            {current !== 'risk' && limitFlag ? (
                <div className="limit">
                    <div className="label">Limit:</div>
                    <InputNumber type="text" ref={limit} />
                </div>
            ) : (
                ''
            )}
        </Modal>
    );
};
export default ChagneModal;
