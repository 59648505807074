import { Select } from 'antd';
import style from './index.module.scss';
import { CSVLink } from 'react-csv';
import { useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
const SelectUser = ({ filterList, csvData, csvHeader, selectFlag, reject }) => {
    const [loading, setLoading] = useState();
    let tierOptions = [
        {
            value: 'NA',
            label: 'Tier 0',
        },
        {
            value: 'Tier1',
            label: 'Tier 1',
        },
        {
            value: 'Tier2',
            label: 'Tier 2',
        },
        {
            value: 'Tier3',
            label: 'Tier 3',
        },
    ];
    let ristOptions = [
        {
            value: 'Low',
            label: 'Risk A',
        },
        {
            value: 'Medium',
            label: 'Risk B',
        },
        {
            value: 'High',
            label: 'Risk C',
        },
    ];
    let rejectOptions = [
        {
            value: 'All',
            label: 'All',
        },
        {
            value: 'Not rejected',
            label: 'Not rejected',
        },
        {
            value: 'Rejected',
            label: 'Rejected',
        },
    ];
    return (
        <div className={style['filter']}>
            {!selectFlag ? (
                <div className="select">
                    <Select
                        defaultValue="Tier"
                        style={{
                            width: 136,
                            border: 'none',
                            fontSize: 14,
                            lineHeight: 40,
                        }}
                        onChange={(e) => filterList(e, 'tier')}
                        options={tierOptions}
                    />
                    <Select
                        defaultValue="Risk"
                        style={{
                            width: 136,
                            border: 'none',
                            fontSize: 14,
                            lineHeight: 40,
                        }}
                        onChange={(e) => filterList(e, 'risk')}
                        options={ristOptions}
                    />
                    {reject ? (
                        <Select
                            defaultValue="All"
                            style={{
                                width: 136,
                                border: 'none',
                                fontSize: 14,
                                lineHeight: 40,
                            }}
                            onChange={(e) => filterList(e, 'reject')}
                            options={rejectOptions}
                        />
                    ) : (
                        ''
                    )}
                </div>
            ) : (
                <div></div>
            )}
            {/* <div className="btn" onClick={() => handleExportCSV(datas, columns)}>
        Export
      </div> */}
            {!csvData && (
                <div
                    className="btn"
                    onClick={() => {
                        setLoading(true);
                    }}
                >
                    Export {loading && <LoadingOutlined></LoadingOutlined>}
                </div>
            )}
            {csvData && csvData.length !== 0 && (
                <CSVLink className="btn" filename={'data.csv'} data={csvData} headers={csvHeader}>
                    DownLoad
                </CSVLink>
            )}
        </div>
    );
};
export default SelectUser;
