import { Principal } from '@dfinity/principal';
export default function blobToBase64(blob) {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.onload = (e) => {
            resolve(e.target.result);
        };
        // readAsDataURL
        fileReader.readAsDataURL(blob);
        fileReader.onerror = () => {
            reject(new Error('blobToBase64 error'));
        };
    });
}

// 判断字符串是不是 Principal
export const isPrincipalText = (text) => {
    if (!text) return false;
    try {
        Principal.fromText(text);
        return true;
    } catch (e) {
        return false;
    }
};
