import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from '@/views/Login';
import App from './App';
import ChangeLog from '@/views/ChangeLog';
import Configurations from '@/views/Configurations';
import AddAccount from '@/views/AddAccount';
import Overview from '@/views/Overview';
import UserDetail from '@/views/UserDetail';
const RouterPage = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/" element={<App />}>
                    <Route path="/ChangeLog" element={<ChangeLog />} />
                    <Route path="/Configurations" element={<Configurations />} />
                    <Route path="/AddAccount" element={<AddAccount />} />
                    <Route index element={<Overview />} />
                    <Route path="/UserDetail" element={<UserDetail />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
};
export default RouterPage;
