import style from './index.module.scss';
import { useNavigate } from 'react-router-dom';
import { apiGetAdminList, changeAdmin } from '@/Api/admin';
import { useCallback, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { message } from 'antd';
import { setUserList } from '@/store/action/index';
const Configurations = ({ jwtToken }) => {
    let [adminList, setAdminList] = useState([]);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        apiGetAdminList(jwtToken)
            .then((res) => {
                setAdminList(res);
                dispatch(setUserList(res));
            })
            .catch((err) => {
                message.error(err.response.data.message);
            });
    }, [dispatch, jwtToken]);

    const changeUser = useCallback(
        (pid) => {
            navigate(`/AddAccount/?admin=${pid}`);
        },
        [navigate],
    );
    const disabledUser = useCallback((item) => {
        changeAdmin({
            userName: item.name,
            disabled: true,
        }).then((res) => {
            message.success('Disable successfully！');
            console.log(res, 'res');
        });
    }, []);

    return (
        <div className={style['config-user']}>
            {/* <div className="search">
        <input type="text" placeholder="User name/Email" />
        <span></span>
      </div> */}
            <div className="config-list">
                <table>
                    <thead style={{ textAlign: 'left' }}>
                        <tr>
                            <th>User name</th>
                            <th>Email</th>
                            <th>Role</th>
                            <th>Action</th>
                            <th>
                                <div className="add" onClick={() => navigate('/AddAccount')}>
                                    Add account
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {adminList.map((item) => {
                            return (
                                <tr key={item._id}>
                                    <td>{item.name}</td>
                                    <td>{item.email}</td>
                                    <td className="role">
                                        <div>{item.role}</div>
                                    </td>
                                    <td>
                                        <div className="option">
                                            <div>
                                                <span onClick={() => changeUser(item.name)}>
                                                    Edit
                                                </span>
                                            </div>
                                            <div>
                                                <span onClick={() => disabledUser(item)}>
                                                    Disabled
                                                </span>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
};
const mapStateToProps = (state) => {
    return { jwtToken: state.Store.token };
};

export default connect(mapStateToProps, {})(Configurations);
